import { Box, Typography, Button } from "@mui/material";
import BasicDatePicker from "../../components/DatePicker";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OrdersTable from "../../components/OrdersTables";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import axiosClient from "../../api/axiosClient";
import dayjs from "dayjs";
import LOGO from "../../assets/iconWithR.png";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

export interface Bill {
  _id: string;
  totalAmount: number;
  modeOfPayment: string;
  billNo: string;
  clientId: string;
  billDate: string;
  billTime: string;
  tax: {
    gstInPercentage: number;
    cgstInPercentage: number;
    igstInPercentage: number;
    gstAmount: number;
    cgstAmount: number;
    igstAmount: number;
    isCompositionDealer: boolean;
  };
  discount: {
    isDiscountApplied: boolean;
    discountInPercentage: number;
    discountAmount: number;
  };
  items: any[]; // Replace any with your item type
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Data {
  sno: number;
  orderId: string;
  orderDate: string;
  quantity: number;
  modeOfPayment: string;
  amount: number;
}

const Reports = () => {
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [todayOrdersCount, setTodayOrdersCount] = useState<number>(0);
  const [todayTotalAmount, setTodayTotalAmount] = useState<number>(0);
  const [todayCashAmount, setTodayCashAmount] = useState<number>(0);
  const [todayUPIAmount, setTodayUPIAmount] = useState<number>(0);
  const [todayCardAmount, setTodayCardAmount] = useState<number>(0);

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      const startTime = fromDate.startOf("day").valueOf();
      const endTime = toDate.endOf("day").valueOf();
      console.log(fromDate.format("DD/MM/YYYY"), endTime);

      const response = await axiosClient().post(
        `/v1/admin/private/api/get-bills?startTime=${startTime}&endTime=${endTime}`
      );

      if (response.data.isSuccess) {
        let todayCount = 0;
        let todayAmount = 0;
        let todayCashAmount = 0;
        let todayUPIAmount = 0;
        let todayCardAmount = 0;
        const today = new Date();
        const formattedToday = today.toISOString().slice(0, 10); // Ensure this matches your date format

        const transformedData: Data[] = response.data.data.bills.map(
          (bill: Bill, index: number) => {
            // const billDate = new Date(bill.createdAt)
            //   .toISOString()
            //   .slice(0, 10);
            todayCount += 1;
            todayAmount += bill.totalAmount;
            if (bill.modeOfPayment === "Cash") {
              todayCashAmount += bill.totalAmount;
            }
            if (bill.modeOfPayment === "UPI") {
              todayUPIAmount += bill.totalAmount;
            }
            if (bill.modeOfPayment === "Card") {
              todayCardAmount += bill.totalAmount;
            }

            return {
              sno: index + 1,
              orderId: bill.billNo,
              orderDate: formatDateToIST(bill.createdAt),
              quantity: bill.items.length,
              modeOfPayment: bill.modeOfPayment,
              amount: bill.totalAmount,
            };
          }
        );

        setTodayOrdersCount(todayCount);
        setTodayTotalAmount(todayAmount);
        setTodayCashAmount(todayCashAmount);
        setTodayUPIAmount(todayUPIAmount);
        setTodayCardAmount(todayCardAmount);
        setData(transformedData);
      } else {
        setError("Failed to fetch bills");
      }
    } catch (error: any) {
      setError(
        error.response?.data?.message ||
        error.message ||
        "An unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateToIST = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour format
    };
    return new Intl.DateTimeFormat("en-IN", options).format(date);
  };

  const today = new Date();
  const formattedDate = `${today.getDate().toString().padStart(2, "0")}/${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${today.getFullYear()}`;

  // Function to handle PDF generation and download
  const downloadPdf = () => {
    const doc = new jsPDF();
    doc.addImage(LOGO,"JPEG", 15, 20, 50, 50);
    doc.setFontSize(15);
    const yAngel= 75;
    doc.text("K.C.SIVA REDDY SWEETS", yAngel, 30);
    doc.setFontSize(10);
    doc.text("Shop No. 311/1A1A, Kallur, Bellary Chowrasta, ", yAngel, 40);
    doc.text("Kurnool-518003, Andhra Pradesh, India.", yAngel, 45);
    doc.text("GST:37AUDPK8206B1ZY ", yAngel, 50);
    doc.text("fssai: 10124012000168 ", yAngel, 55);
    doc.text("From: "+fromDate.format("DD/MM/YYYY"), yAngel, 60);
    doc.text("To: "+toDate.format("DD/MM/YYYY"), yAngel+60, 60);

    const tableColumn = [
      "S No",
      "Bill ID",
      "Order Date",
      "Quantity",
      "Mode Of Payment",
      "Amount",
    ];
    const tableRows: string[][] = [];

    // Populate tableRows with data from rows
    data.forEach((row: Data) => {
      const rowData = [
        row.sno.toString(),
        row.orderId,
        row.orderDate,
        row.quantity.toString(),
        row.modeOfPayment,
        row.amount.toString(),
      ];
      tableRows.push(rowData);
    });

    // Start position for the table
    let startY = 80;

    // Add a table to the PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: startY,
      didDrawPage: (data: any) => {
        startY = data.cursor.y; // Update startY after autoTable has finished drawing
      },
    });

    // Set font size smaller
    doc.setFontSize(10); // Smaller font size for the summary

    // After the table, continue with summary data
    startY += 10; // Add some padding after the table

    // Format two records per row by adjusting x and y coordinates
    doc.text(`Order Count: ${todayOrdersCount}`, 14, startY);
    doc.text(`Total Amount: ${todayTotalAmount.toFixed(2)}`, 105, startY); // Second column

    startY += 10; // Move to the next line
    doc.text(`Cash Payments: ${todayCashAmount.toFixed(2)}`, 14, startY);
    doc.text(`UPI Payments: ${todayUPIAmount.toFixed(2)}`, 105, startY); // Second column

    startY += 10; // Move to the next line
    doc.text(`Card Payments: ${todayCardAmount.toFixed(2)}`, 14, startY);

    // Save the PDF
    doc.save("past_orders.pdf");
  };

  const handleFromDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setFromDate(newValue);
    }
  };

  const handleToDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setToDate(newValue);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ mr: 2, fontWeight: "bold" }}>OVERVIEW</Typography>
        <Typography variant="subtitle1">{`${fromDate.format("DD/MM/YYYY")}-${toDate.format("DD/MM/YYYY")}`}</Typography>
      </Box>
      <Box
        sx={{ display: "flex", my: 3, gap: 3, justifyContent: "space-around" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFF5E5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#C97C00" }}>
            {todayOrdersCount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total orders</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayTotalAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Grand Total</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayCashAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total Cash Amount</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayUPIAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total UPI Amount</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#EEF9F5",
            padding: "1rem",
            borderRadius: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "2rem", color: "#007248" }}>
            ₹ {todayCardAmount}
          </Box>
          <Typography sx={{ fontSize: "0.8rem" }}>Total Card Amount</Typography>
        </Box>
      </Box>
      <Typography sx={{ mr: 2, fontWeight: "bold" }}>Past Orders</Typography>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <BasicDatePicker
          label="From Date"
          value={fromDate}
          onChange={handleFromDateChange}
        />
        <BasicDatePicker
          label="To Date"
          value={toDate}
          onChange={handleToDateChange}
        />
        <Button variant="contained" onClick={fetchBills}>
          Get Past Orders
        </Button>
        <Button variant="outlined" onClick={downloadPdf}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CloudDownloadIcon sx={{ marginRight: "5px" }} fontSize="small" />
            Download Report
          </Box>
        </Button>
      </Box>
      <OrdersTable bills={data} />
    </div>
  );
};

export default Reports;
